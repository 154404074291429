<template>
  <DetailLayout title="Account Settings" :nav-items="navItems">
    <slot />
  </DetailLayout>
</template>

<script setup lang="ts">
import DetailLayout from './detail.vue'

const { role } = useCurrentRole()

const navItems = computed(() => {
  // Admin Nav Items
  const adminOrgs = {
    label: 'Orgs',
    path: '/admin/orgs',
  }
  const adminIcons = {
    label: 'Icons',
    path: '/admin/icons',
  }
  // Manage Nav Items
  const myProfile = {
    label: 'My Profile',
    path: wrapPathWithOrg('/manage/profile'),
  }
  const myTeam = {
    label: 'My Team',
    path: wrapPathWithOrg('/manage/team'),
  }
  const myOrg = {
    label: 'My Organization',
    path: wrapPathWithOrg('/manage/org'),
  }

  switch (role.value) {
    case 'member':
      return [myProfile]
    case 'admin':
      return [myProfile, myTeam]
    case 'owner':
      return [myProfile, myTeam, myOrg]
  }
})
</script>
